/**
 * @ngdoc directive
 * @module uploadModule
 * @name fileUpload
 * @param file
 *          This is the File to be uploaded
 * @param uploadUrl
 *          This is the Rest service to upload the File and Form Data
 * @param formData
 *          This is the Form Data to be uploaded along with File data
 * @description This directive is used to upload Multipart File/Form Data from
 *              UI to Rest Services This can send the uploaded file into server
 *              along with Form Data The required FormData need to be passed as
 *              parameter This directive simply creates the scope variable for
 *              the file name specified
 */
(function() {
  'use strict';
  angular.module('uploadModule', []);

  angular.module('uploadModule').service('fileUploadSvc',
          ['$http', '$log', '$q', function($http, $log, $q) {
            this.uploadFileToUrl = function(file, uploadUrl, formData) {
              return $http.post(uploadUrl, formData, {
                transformRequest: angular.identity,
                headers: {
                  'Content-Type': undefined
                }
              }).then(function(success) {
                $log.debug('Upload is Successful');
                return success;
              }, function(error) {
                return $q.reject(error);
              });
            };
          }]);

  angular.module('uploadModule').directive('fileUpload',
          ['$parse', '$log', function($parse, $log) {
            $log.debug('file upload is called');
            return {
              restrict: 'A',
              link: function(scope, element, attrs) {
                $log.debug('attrs.fileUpload:', attrs.fileUpload);
                var model = $parse(attrs.fileUpload);
                var modelSetter = model.assign;

                element.bind('change', function() {
                  $log.debug('change event is triggerred');
                  scope.$apply(function() {
                    $log.debug('file : ', element[0].files[0]);
                    modelSetter(scope, element[0].files[0]);
                  });
                });
              }
            };
          }]);

}());
